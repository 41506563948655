<template>
  <v-container fluid class="navbar-line navbar-shop">
    <div class="navbar-row d-flex align-center">
      <!-- menu col -->
      <div
        class="menu-column menu-btn order-0 d-flex justify-start align-center column pl-4 pl-md-0 pr-md-4 pr-lg-8"
      >
        <v-btn
          v-if="site"
          aria-label="Apri menu categorie"
          icon
          class="open-menu-btn"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <v-icon color="grey darken-1">$menu</v-icon>
          {{ $t("navbar.products") }}
        </v-btn>
      </div>
      <!-- logo col -->
      <div class="logo-column order-1 column">
        <router-link
          :to="site == 'site' ? { name: 'HomeSite' } : { name: 'Home' }"
          class="d-flex align-center"
        >
          <!-- logo desktop -->
          <img
            class="px-0 logo-desktop d-none d-md-flex"
            src="/logo/logo.svg"
            alt="Logo Basko"
            :style="{ height: '44px' }"
          />
          <!-- logo mobile -->
          <img
            class="px-0 logo-mobile mx-auto d-flex d-md-none"
            src="/logo/logo.svg"
            alt="Logo Basko"
            :style="{ height: '36px' }"
          />
        </router-link>
      </div>
      <!-- search col -->
      <div
        class="search-input-column order-4 order-md-2 column d-flex px-4 px-sm-0 px-md-4 px-lg-8 mt-5 mt-md-0"
      >
        <SearchInput />
      </div>
      <!-- buttons col -->
      <div
        class="buttons-column column order-2 order-md-3 d-flex justify-end align-center"
      >
        <v-layout v-if="!isAuthenticated" align-center justify-end>
          <div class="d-flex align-center">
            <!-- sloggato -->
            <v-btn
              tile
              depressed
              color="white"
              class="profile-btn"
              aria-label="Vai al profilo"
              @click.stop="handleLogin()"
            >
              <v-icon color="grey darken-1">$profile</v-icon>
              <div class="username d-none d-lg-block  text-caption ml-2">
                <span>{{ $t("navbar.userMessage") }}</span> <br />
                <strong>{{ $t("navbar.userMessageNotLogged") }}</strong>
              </div>
            </v-btn>
            <v-btn
              aria-label="Apri il carrello"
              icon
              @click.stop="handleLogin()"
            >
              <v-icon color="grey darken-1">$cart</v-icon>
            </v-btn>
          </div>
        </v-layout>
        <v-layout v-else justify-end align-center>
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aria-label="Vai al profilo"
                v-bind="attrs"
                v-on.stop="on"
                tile
                depressed
                color="white"
                class="profile-btn"
              >
                <v-icon color="primary">$profileFull</v-icon>
                <div class="username d-none d-lg-block text-caption ml-2">
                  <span>{{ $t("navbar.userMessage") }}</span> <br />
                  <strong>{{ cart.user.firstName }}</strong>
                </div>
              </v-btn>
            </template>
            <UserMenu />
          </v-menu>
          <transition name="shake" mode="out-in">
            <v-badge
              :content="cart.totalItems"
              :value="cart.totalItems > 0"
              :key="cart.totalItems"
              transition="bounce"
              color="green shopping-cart-icon"
              overlap
              offset-x="24"
              offset-y="20"
              class="primary--text font-weight-bold"
            >
              <v-btn
                :key="cart.refreshTime"
                class="cart-total-logged"
                :class="{ disabled: !editableCart }"
                @click.stop="openCart()"
                aria-label="Apri carrello"
                color="green"
                depressed
                :icon="$vuetify.breakpoint.smAndDown"
                large
              >
                <v-icon color="grey darken-1" class="cart-icon">
                  $cart
                </v-icon>

                <div class="cart-total d-none d-md-flex" role="button">
                  <span class="default--text px-4" style="line-height:1">
                    <i18n-n
                      :value="cart.totalPrice"
                      format="currency"
                      tag="span"
                    >
                      <template v-slot:literal></template>
                    </i18n-n>
                    &nbsp;-&nbsp;
                    {{ $tc("navbar.cartProducts", cart.totalItems) }}
                  </span>
                </div>
              </v-btn>
            </v-badge>
          </transition>
        </v-layout>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.navbar-shop {
  .search-input-column {
    flex-grow: 2;
    .search-input-container {
      width: 100%;
      max-width: 800px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .navbar-row {
      flex-wrap: wrap;
    }
    .buttons-column,
    .menu-column {
      flex-basis: 25%;
    }
    .logo-column {
      flex-basis: 50%;
    }
    .search-input-column {
      flex-basis: 100%;
      .search-input-container {
        flex-basis: 100%;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .buttons-column {
      flex-basis: 30%;
      .profile-btn.v-btn.v-size--default {
        padding: 0px 8px;
      }
    }
    .menu-column {
      flex-basis: 20%;
    }
    .logo-column {
      flex-basis: 48%;
    }
  }
  @media (max-width: 374px) {
    .buttons-column {
      flex-basis: 34%;
    }
    .menu-column {
      flex-basis: 20%;
    }
    .logo-column {
      flex-basis: 44%;
      .logo-mobile {
        height: 30px !important;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .search-input-column {
      justify-content: center;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .search-input-column .search-input-container {
      flex-basis: 90%;
    }
  }
  .shopping-cart-icon.v-badge__badge {
    color: var(--v-default-base) !important;
    padding: 4px 4px;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: none;
    }
  }
}
.shake-enter-active {
  animation: shakeCart 0.4s ease-in-out forwards;
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";

import login from "~/mixins/login";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Navbar",
  mixins: [login],
  components: {
    SearchInput,
    UserMenu
  },
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      // updatedDrawerLeftSite: "app/updatedDrawerLeftSite",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "HomeSite",
            path: "/"
          });
        }, 200);
      }
    },
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push({ name: "Cart" });
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      site: "custom/getSite"
    }),
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  mounted() {}
};
</script>
